import { Box, Button, Chip, CircularProgress, Dialog, Stack, Typography } from '@mui/material';
import palette from '../../shared/theme/palette';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../shared/service/dataprovider';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function LeaseRequests({ person }) {
  const { t } = useTranslation();
  const [showViewAll, setShowViewAll] = useState(false);

  const { isLoading, error, data } = useQuery({
    queryKey: [`workOrders/${person.id}`],
    queryFn: () => api.get(`/v1/admin/work-orders`, { params: { personId: person.id } }),
  });
  const workOrders = data?.data;
  if (isLoading) {
    return (
      <Box
        sx={{
          px: 3,
          py: 6,
          textAlign: 'center',
          borderRadius: 2,
          boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }
  if (error) {
    return (
      <Typography variant="h6" sx={{ color: palette.error[50] }}>
        {error?.response?.data?.message ?? `There is an error for loading work orders for person ${person.id}. Try again later`}
      </Typography>
    );
  }
  if (!workOrders) return null;

  const firstWorkOrders = workOrders.slice(0, 5);
  return (
    <Box
      sx={{
        p: 2,
        pb: 0,
        borderRadius: 2,
        boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', color: palette.grey[50] }}>{t('lease.request.title')}</Typography>
      </Stack>
      {workOrders?.length > 0 ? (
        <Stack gap={3} sx={{ mb: 2 }}>
          {firstWorkOrders.map((workOrder, i) => (
            <WorkOrderItem key={`w-${i}`} workOrder={workOrder} />
          ))}
        </Stack>
      ) : (
        <Typography>{t('lease.request.empty')}</Typography>
      )}
      <Button
        disabled={!workOrders?.length}
        fullWidth
        variant="text"
        onClick={() => setShowViewAll(true)}
        sx={{ fontSize: '14px', fontWeight: 500, textDecoration: 'underline' }}
      >
        {t('lease.transaction.viewAll')}
      </Button>
      {showViewAll && <ViewAllModal workOrders={workOrders} onClose={() => setShowViewAll(false)} />}
    </Box>
  );
}

function ViewAllModal({ workOrders, onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth PaperProps={{ sx: { maxWidth: '424px', maxHeight: 'calc(100% - 128px)' } }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 3 }}>
        <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', color: palette.grey[50] }}>{t('lease.request.title')}</Typography>
      </Stack>
      <Stack gap={3} sx={{ overflow: 'auto', p: 3, pt: 1 }}>
        {workOrders.map((workOrder, i) => (
          <WorkOrderItem key={`w-${i}`} workOrder={workOrder} />
        ))}
      </Stack>
      <Button fullWidth variant="text" onClick={onClose} sx={{ fontSize: '14px', fontWeight: 500, textDecoration: 'underline' }}>
        {t('common.close')}
      </Button>
    </Dialog>
  );
}

function WorkOrderItem({ workOrder }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box>
        <Typography color="grey.800" sx={{ fontWeight: 600 }}>
          {workOrder.title}
        </Typography>
        <Typography variant="caption" color="grey.500">
          {moment(workOrder.ReportedDate).format('MM/DD/YY')}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography color="grey.800" sx={{ fontWeight: 600 }}>
          {`#${workOrder.Number}`}
        </Typography>
        <Chip variant="filled" size="small" label={workOrder.Status} sx={{ ...(colors[workOrder.Status] ?? {}) }} />
      </Box>
    </Stack>
  );
}

const colors = {
  Completed: {
    backgroundColor: palette.success[70],
    color: palette.success[50],
  },
  Submitted: {
    backgroundColor: palette.success[70],
    color: palette.success[50],
  },
  Cancelled: {
    backgroundColor: palette.warning[70],
    color: palette.error[50],
  },
  'Not Started': {
    backgroundColor: palette.primary[10],
    color: palette.primary[40],
  },
};
