import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../Login';

export default function LandingPage() {
  const { isAuthenticated, isLoading } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated]);

  if (!isAuthenticated && !isLoading) {
    return <Login />;
  }

  return <></>;
}
