import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithNavigate from './providers/Auth0ProviderWithNavigate';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import theme from './shared/theme/theme';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './providers/queryClient';
import { I18nextProvider } from 'react-i18next';
import i18n from './locale/i18n';
import { Toaster } from 'react-hot-toast';
import { init as initFullStory } from '@fullstory/browser';

const root = ReactDOM.createRoot(document.getElementById('root'));

initFullStory({ orgId: 'o-1CZ4ZR-na1', recordCrossDomainIFrames: true, devMode: process.env.REACT_APP_ENV !== 'prod' });

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Auth0ProviderWithNavigate>
          <I18nextProvider i18n={i18n}>
            <App />
            <Toaster position="top-center" />
          </I18nextProvider>
        </Auth0ProviderWithNavigate>
      </QueryClientProvider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
