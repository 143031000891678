import {
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Search as SearchInputIcon } from '@mui/icons-material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import { api } from '../../shared/service/dataprovider';
import palette from '../../shared/theme/palette';
import Pagination from '../../components/pagination';

export default function Properties() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['properties'],
    queryFn: () => api.get('/v1/admin/properties'),
  });
  const searchQuery = queryClient.getQueryData(['properties/search']);

  const [leases, setLeases] = useState([]);
  const [isFetchingLeases, setFetchingLeases] = useState(false);
  // Search Status
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [leaseStatus, setLeaseStatus] = useState('');
  const [search, setSearch] = useState('');
  const [name, setName] = useState('');

  // Pagination Status
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const totalLeaseLength = leases.length;

  const navigate = useNavigate();

  useEffect(() => {
    if (searchQuery) {
      setSelectedProperty(searchQuery.selectedProperty);
      setSelectedUnit(searchQuery.selectedUnit);
      setLeaseStatus(searchQuery.leaseStatus);
      setName(searchQuery.name);
      setSearch(searchQuery.name);
    }
    if (data && data.data && data.data.length && !selectedProperty && !searchQuery) {
      setSelectedProperty(data.data[0].id);
    }
  }, [selectedProperty, data, searchQuery]);

  useEffect(() => {
    if (selectedUnit || name) {
      searchLeases();
    }
  }, [selectedUnit, leaseStatus, name]);

  const units = useMemo(() => {
    return data?.data.find(d => d.id === selectedProperty)?.units?.sort((u1, u2) => u1.unitNumber.localeCompare(u2.unitNumber));
  }, [data, selectedProperty]);

  const handleSearchChange = n => {
    setName(n);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  const searchLeases = async () => {
    if (selectedUnit || name) {
      try {
        if (leases.length === 0) {
          setFetchingLeases(true);
        }
        const leaseResp = await api.get(
          `/v1/admin/lease?property_id=${selectedProperty}${selectedUnit ? `&unit=${selectedUnit}` : ''}${leaseStatus ? '&status=' + leaseStatus : ''}${name ? `&name=${name}` : ''}`
        );
        setLeases(leaseResp.data);
      } finally {
        setFetchingLeases(false);
      }
    } else {
      setLeases([]);
    }
  };

  const renderLeaseTable = () => {
    if (!selectedUnit && !name) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '226px',
            borderRadius: '16px',
            border: '1px solid #D5D7DB',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, justifyContent: 'center', alignItems: 'center' }}>
            <SearchIcon />
            <Typography sx={{ fontSize: '16px', color: palette.grey[50] }}>{t('properties.search')}</Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ borderRadius: 4, border: `1px solid ${palette.grey[20]}`, p: 3 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  backgroundColor: '#EBF2F3',
                  border: 'unset',
                  padding: '10px 16px 10px 24px',
                  '&:first-child': {
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  },
                  '&:last-child': {
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  },
                },
              }}
            >
              <TableCell>{t('properties.unitNumber')}</TableCell>
              <TableCell>{t('properties.residentName')}</TableCell>
              <TableCell>{t('properties.status')}</TableCell>
              <TableCell>{t('properties.ssnVerified')}</TableCell>
              <TableCell>{t('properties.registered')}</TableCell>
              <TableCell>{t('properties.startDate')}</TableCell>
              <TableCell>{t('properties.endDate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetchingLeases ? (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: 'center', py: 8 }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : totalLeaseLength > 0 ? (
              <>
                {leases.slice(rowsPerPage * (currentPage - 1), rowsPerPage * currentPage).map(row => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer',
                      '& td': {
                        textAlign: 'left',
                        padding: '20px 16px 20px 24px',
                      },
                    }}
                    onClick={() => {
                      queryClient.setQueryData(['properties/search'], {
                        selectedProperty,
                        selectedUnit,
                        leaseStatus,
                        name,
                      });
                      navigate(`/lease/${row.id}`);
                    }}
                  >
                    <TableCell>{row.Unit}</TableCell>
                    <TableCell sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', flexDirection: 'row' }}>
                      {row.leaseLinks.map(ll => (
                        <Typography key={ll.id} sx={{ color: palette.primary[50], background: '#EBF2F3', borderRadius: '8px', padding: '4px 8px' }}>
                          {row.leaseLinks.length > 1
                            ? ll.people.FirstName + ' ' + ll.people.LastName.substring(0, 1)
                            : ll.people.FirstName + ' ' + ll.people.LastName}
                        </Typography>
                      ))}
                    </TableCell>
                    <TableCell>{row.Status}</TableCell>
                    <TableCell>
                      {row.leaseLinks.filter(ll => ll.people.kycStatus === 'Success').length}/{row.leaseLinks.length}
                    </TableCell>
                    <TableCell>
                      {row.leaseLinks.filter(ll => ll.people.user).length}/{row.leaseLinks.length}
                    </TableCell>
                    <TableCell>{moment(row.LeaseStartDate).format('MM/DD/YYYY')}</TableCell>
                    <TableCell>{moment(row.LeaseEndDate).format('MM/DD/YYYY')}</TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={7} sx={{ textAlign: 'center', py: 8 }}>
                  {selectedUnit ? t('properties.emptySearchWithUnit', { unit: selectedUnit }) : t('properties.empty')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    );
  };

  return (
    <Box>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography variant="h2">{t('properties.title')}</Typography>
          <Divider orientation="vertical" sx={{ width: '3px', height: '24px' }} />
          <Select
            variant="standard"
            value={selectedProperty}
            onChange={e => {
              setSelectedProperty(e.target.value);
              setSelectedUnit('');
              setLeaseStatus('');
              queryClient.setQueryData(['properties/search'], '');
              setLeases([]);
            }}
            sx={{ fontSize: '21px', '::before': { borderBottom: '0px' } }}
          >
            {data?.data.map(p => (
              <MenuItem key={p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex', gap: 3, mb: 4 }}>
        <TextField
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            if (e.target.value === '' || e.target.value.length > 2) {
              debouncedResults(e.target.value);
            }
          }}
          placeholder="Search for resident name"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchInputIcon />
              </InputAdornment>
            ),
          }}
          sx={{ minWidth: '312px' }}
        />
        <Select
          displayEmpty
          value={selectedUnit}
          onChange={e => {
            setSelectedUnit(e.target.value);
            setLeaseStatus('');
          }}
          sx={{ minWidth: '240px' }}
          renderValue={selected => {
            if (!selected) {
              return <>{t('properties.unitNumber')}</>;
            }
            return selected;
          }}
        >
          <MenuItem key="Blank" value="">
            All
          </MenuItem>
          {units?.map(u => (
            <MenuItem key={u.id} value={u.unitNumber}>
              {u.unitNumber}
            </MenuItem>
          ))}
        </Select>
        <Select
          displayEmpty
          value={leaseStatus}
          onChange={e => {
            setLeaseStatus(e.target.value);
          }}
          sx={{ minWidth: '240px' }}
          renderValue={selected => {
            if (!selected) {
              return <>All Status</>;
            }
            return selected;
          }}
        >
          <MenuItem key="Blank" value="">
            All
          </MenuItem>
          <MenuItem key="Current" value="Current">
            {t('properties.current')}
          </MenuItem>
          <MenuItem key="Pending" value="Pending">
            {t('properties.pending')}
          </MenuItem>
          <MenuItem key="Pending Transfer" value="Pending Transfer">
            {t('properties.pendingTransfer')}
          </MenuItem>
          <MenuItem key="Former" value="Former">
            {t('properties.former')}
          </MenuItem>
          <MenuItem key="Evicted" value="Evicted">
            {t('properties.evicted')}
          </MenuItem>
          <MenuItem key="Under Eviction" value="Under Eviction">
            {t('properties.underEviction')}
          </MenuItem>
        </Select>
      </Box>
      {renderLeaseTable()}
      <Typography variant="caption" color="grey.400">
        TP = TenantPort
      </Typography>
      {totalLeaseLength > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPage={Math.ceil(totalLeaseLength / rowsPerPage)}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={p => {
            setCurrentPage(1);
            setRowsPerPage(p);
          }}
          onChangeCurrentPage={p => setCurrentPage(p)}
        />
      )}
    </Box>
  );
}
