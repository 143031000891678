import { Button, Dialog, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ResetPasswordConfirmModal({ onClose, onSubmit }) {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" PaperProps={{ sx: { maxWidth: '472px', p: 3 } }}>
      <Typography variant="h4" sx={{ textAlign: 'center', mb: 1.5 }}>
        {t('lease.password.resetTitle')}
      </Typography>
      <Typography color="grey.600" sx={{ mb: 3, textAlign: 'center' }}>
        {t('lease.password.resetDescription')}
      </Typography>
      <Stack alignItems="center" direction="row" gap={2} sx={{ px: 3 }}>
        <Button onClick={onClose} variant="outlined" color="primary" sx={{ flex: 1 }}>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} variant="contained" color="primary" sx={{ flex: 1 }}>
          {t('lease.password.resetPassword')}
        </Button>
      </Stack>
    </Dialog>
  );
}
