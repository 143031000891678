import { Auth0Provider } from '@auth0/auth0-react';
import { configs } from '../config';
import { useNavigate } from 'react-router-dom';

export default function Auth0ProviderWithNavigate({ children }) {
  const navigate = useNavigate();

  const domain = configs.auth0_domain;
  const clientId = configs.auth0_clientId;
  const redirectUri = window.location.origin;

  const onRedirectCallback = appState => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        useRefreshTokens: true,
        audience: configs.api_audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
