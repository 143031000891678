export const en = {
  landing: {
    title: 'Log Into your Account',
    signin: 'Sign in with Google',
  },
  lease: {
    back: 'Back to search',
    resident: {
      title: 'Resident',
      name: 'Name:',
      email: 'Email:',
      phone: 'Phone Number:',
      leasingFrom: 'Leasing From:',
      password: 'Password:',
      lastLogin: 'Last Login',
      registered: 'Registered in TenantPort',
      editingEmail: 'This new email will automatically update in Resman.',
      outsync: 'Data is currently out of sync with Resman',
      ssnVerified: 'SSN Verified in TenantPort',
      credbuild: 'Opt-Out CredBuild',
      removeSSN: 'Remove SSN Requirement',
      resetPassword: 'Reset Password',
      inviteSent: 'Invite Sent:',
      invitationSent: 'Invitation Sent',
      inviteResent: 'Invitation Resent!',
      resendInvitation: 'Resend Invitation',
      sendInvitation: 'Send Invitation',
      residentInfo: 'To edit details of the resident, please update in Resman, then press Sync to show changes',
      sync: 'Sync w/Resman',
      status: 'Status:',
      moveOutDate: 'Move out date:',
    },
    payment: {
      title: 'Payment Info',
      empty: 'There are no payment methods',
      autoPay: 'Auto Pay',
      autoPayDate: 'Auto Pay Date',
      on: 'On',
      off: 'Off',
      status: 'Online Payment Status',
      enabled: 'Enabled',
      disabled: 'Disabled',
      certifiedFunds: 'Certified Funds Only',
      description: 'Online Payments have been disabled due to failed attempts. Please reach out to your RM to change this status.',
    },
    transaction: {
      title1: 'Resman Ledger',
      title2: 'Transactions',
      empty: 'There are no transactions',
      viewAll: 'View All',
    },
    request: {
      title: 'Requests',
      empty: 'There are no work orders',
    },
    password: {
      resetTitle: 'Are You Sure You Want to Reset Their password?',
      resetDescription: 'They will receive a email asking them to create a new password.',
      resetPassword: 'Rest Password',
      changeTitle: "Change the Tenant's Password",
      changeDescription: 'This allows you to change the password on the tenants behalf. Please make sure to notify the tenant on the new password',
      changePassword: 'Change Password',
      credbuildTitle: 'Are You Sure You Want to Disable and End the Tenants CredBuild Subscription?',
      credbuildDescription: 'This will take affect on the next months bill cycle.',
    },
    success: {
      invite: 'Invite was resent to {{ property }}',
    },
  },
  logout: {
    title: 'Log Out',
    description: 'Are you sure you want to log out?',
    return: 'Return',
  },
  pagination: {
    rowsPerPage: 'Rows per page',
  },
  properties: {
    title: 'Search for Residents',
    unitNumber: 'Unit Number',
    current: 'Current',
    renewed: 'Renewed',
    pending: 'Pending',
    pendingTransfer: 'Pending Transfer',
    former: 'Former',
    evicted: 'Evicted',
    underEviction: 'Under Eviction',
    search: 'Select a unit number to start your search',
    residentName: 'Resident(s) Name',
    status: 'Lease Status',
    ssnVerified: 'SSN Verified in TP',
    registered: 'Registered in TP',
    startDate: 'Lease Start Date',
    endDate: 'Lease End Date',
    empty: 'There are no residents based off of that search criteria.',
    emptySearchWithUnit: 'There are no residents for Unit {{ unit }} based off of that search criteria.',
  },
  common: {
    cancel: 'Cancel',
    save: 'Save',
    reset: 'Reset',
    change: 'Change',
    close: 'Close',
    success: 'Success',
    pending: 'Pending',
    skipped: 'Skipped',
    failed: 'Failed',
    notRequired: 'Not Required',
    default: 'Default',
    disable: 'Disable',
  },
};
