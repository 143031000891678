import axios from 'axios';
import { configs } from '../../config';

export const api = axios.create({
  baseURL: configs.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getList = async (resource, params) => {
  const { data } = await api.get(`/${resource}`);
  return { data };
};

const getOne = async (resource, params) => {
  const { data } = await api.get(`/${resource}/${params.id}`);
  return { data };
};

export const dataProvider = { getList, getOne };
