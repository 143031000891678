import { Button, Dialog, Stack, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { api } from '../../shared/service/dataprovider';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export default function ResetPasswordModal({ userId, residentName, onClose }) {
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const resetPasswordMutation = useMutation({
    mutationFn: payload => api.post('/v1/admin/change-password', payload),
    onSuccess: () => {
      toast.success(`The password for ${residentName} has been reset.`);
      onClose();
    },
  });

  const isDisabled = useMemo(() => !newPassword || newPassword !== confirmPassword, [newPassword, confirmPassword]);

  const handleSubmit = () => {
    resetPasswordMutation.mutate({
      userId,
      password: newPassword,
    });
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm" PaperProps={{ sx: { maxWidth: '472px', p: 3 } }}>
      <Typography variant="h4" sx={{ textAlign: 'center', mb: 1.5 }}>
        {t('lease.password.changeTitle')}
      </Typography>
      <Typography color="grey.600" sx={{ mb: 4, textAlign: 'center' }}>
        {t('lease.password.changeDescription')}
      </Typography>
      <Stack gap={3} sx={{ mb: 5 }}>
        <TextField value={newPassword} type="password" label="New Password" onChange={e => setNewPassword(e.target.value)} fullWidth />
        <TextField value={confirmPassword} type="password" label="Confirm Password" onChange={e => setConfirmPassword(e.target.value)} fullWidth />
      </Stack>
      <Stack alignItems="center" justifyContent="flex-end" direction="row" gap={2} sx={{ px: 3 }}>
        <Button onClick={onClose} variant="outlined" color="primary">
          {t('common.cancel')}
        </Button>
        <Button disabled={isDisabled} onClick={handleSubmit} variant="contained" color="primary">
          {t('lease.password.changePassword')}
        </Button>
      </Stack>
    </Dialog>
  );
}
