import { Box, Button, MenuItem, Select, Stack, Typography } from '@mui/material';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import palette from '../shared/theme/palette';
import { useTranslation } from 'react-i18next';

export default function Pagination({ totalPage, currentPage, rowsPerPage, onChangeRowsPerPage, onChangeCurrentPage }) {
  const { t } = useTranslation();

  const handlePrevPage = () => {
    onChangeCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    onChangeCurrentPage(currentPage + 1);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4} sx={{ mt: 1.5 }}>
      <Stack direction="row" gap={1} alignItems="center">
        <Button
          size="small"
          variant="outlined"
          disabled={currentPage === 1}
          onClick={handlePrevPage}
          sx={{ p: 1, borderRadius: 1, border: '1px solid #E8EAED', width: '26px', height: '26px', minWidth: 'auto', color: palette.grey[90] }}
        >
          <ChevronLeftOutlinedIcon />
        </Button>
        <Box sx={{ px: 1, py: 0.5, borderRadius: 1, border: '1px solid #E8EAED', width: '24px', height: '24px', fontSize: '12px', boxSizing: 'border-box' }}>
          {currentPage}
        </Box>
        <Typography variant="caption">/</Typography>
        <Typography variant="caption">{totalPage}</Typography>
        <Button
          size="small"
          variant="outlined"
          disabled={currentPage === totalPage}
          onClick={handleNextPage}
          sx={{ p: 1, borderRadius: 1, border: '1px solid #E8EAED', width: '26px', height: '26px', minWidth: 'auto', color: palette.grey[90] }}
        >
          <ChevronRightOutlined />
        </Button>
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="caption">{t('pagination.rowsPerPage')}</Typography>
        <Select size="small" value={rowsPerPage} onChange={e => onChangeRowsPerPage(e.target.value)}>
          {rowOptions.map(row => (
            <MenuItem key={row} value={row}>
              {row}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
}

const rowOptions = [10, 25, 50];
