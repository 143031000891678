import { Box, Chip, Dialog, Stack, Tab, Tabs, Typography, Button, CircularProgress } from '@mui/material';
import palette from '../../shared/theme/palette';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../shared/service/dataprovider';
import moment from 'moment';
import { formatter } from '../../shared/util';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { lowerCase, startCase, upperFirst } from 'lodash';

export default function ResmanDetail({ lease, person }) {
  const { t } = useTranslation();
  const [showViewAll, setShowViewAll] = useState(false);
  const [selectedTab, setSelectedTab] = useState('resman');

  const { isLoading, error, data } = useQuery({
    queryKey: [`transactionHistory/${lease.id}`],
    queryFn: () =>
      api.get(`/v1/admin/lease/transaction-history`, { params: { leaseId: lease.id, dateFrom: lease.LeaseStartDate, dateTo: lease.LeaseEndDate } }),
  });
  const transactions = data?.data?.transactions;
  if (isLoading) {
    return (
      <Box
        sx={{
          px: 3,
          py: 6,
          textAlign: 'center',
          borderRadius: 2,
          boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }
  if (error) {
    return (
      <Typography variant="h6" sx={{ color: palette.error[50] }}>
        {error?.response?.data?.message ?? `There is an error for loading transaction history for lease ${lease.id}. Try again later`}
      </Typography>
    );
  }
  if (!transactions) return null;

  const firstTransactions = transactions.slice(0, 5);

  return (
    <Box
      sx={{
        px: 3,
        pt: 2.5,
        borderRadius: 2,
        boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <Tabs value={selectedTab} onChange={(_e, value) => setSelectedTab(value)} sx={{ minHeight: 'auto', mb: 2, width: '100%' }}>
        <Tab
          value="resman"
          label={t('lease.transaction.title1')}
          sx={{ py: 1, minHeight: 'auto', fontWeight: 600, color: palette.secondary[40], borderBottom: '1px solid #D4D7DC', flex: 1 }}
        />
        <Tab
          value="transactions"
          label={t('lease.transaction.title2')}
          sx={{ py: 1, minHeight: 'auto', fontWeight: 600, color: palette.secondary[40], borderBottom: '1px solid #D4D7DC', flex: 1 }}
        />
      </Tabs>
      {selectedTab === 'resman' ? (
        <Stack gap={3} sx={{ mb: 2 }}>
          {person.balance && person.balance.length ? (
            <ResmanLedgerItem
              key={person.id + '_balance'}
              transaction={{ TransactionCategory: 'Current Balance', date: new Date(), amount: '' + person.balance[0].Balance, type: 'Total' }}
            />
          ) : (
            <></>
          )}
          {firstTransactions.map(transaction => (
            <ResmanLedgerItem key={transaction.transactionId} transaction={transaction} />
          ))}
        </Stack>
      ) : (
        <Stack gap={3} sx={{ mb: 2 }}>
          {person.balance && person.balance.length ? (
            <ResmanLedgerItem
              key={person.id + '_balance'}
              transaction={{ TransactionCategory: 'Current Balance', date: new Date(), amount: '' + person.balance[0].Balance, type: 'Total' }}
            />
          ) : (
            <></>
          )}
          {person.relatedUser?.transactions ? (
            <>
              {person.relatedUser.transactions
                .filter(t => t.leaseId === lease?.id)
                .sort((t1, t2) => new Date(t2.createdDate) - new Date(t1.createdDate))
                .slice(0, 5)
                .map(transaction => (
                  <TransactionItem key={transaction.id} transaction={transaction} person={person} />
                ))}
            </>
          ) : person.balance && person.balance.length ? (
            <></>
          ) : (
            <Typography>{t('lease.transaction.empty')}</Typography>
          )}
        </Stack>
      )}
      <Button fullWidth variant="text" onClick={() => setShowViewAll(true)} sx={{ fontSize: '14px', fontWeight: 500, textDecoration: 'underline' }}>
        {t('lease.transaction.viewAll')}
      </Button>
      {showViewAll && <ViewAllModal person={person} transactions={transactions} lease={lease} onClose={() => setShowViewAll(false)} />}
    </Box>
  );
}

function ViewAllModal({ person, transactions, onClose, lease }) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('resman');

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth PaperProps={{ sx: { maxWidth: '424px', maxHeight: 'calc(100% - 128px)', px: 3, pt: 2 } }}>
      <Tabs value={selectedTab} onChange={(_e, value) => setSelectedTab(value)} sx={{ minHeight: '36px', mb: 2, width: '100%' }}>
        <Tab
          value="resman"
          label={t('lease.transaction.title1')}
          sx={{ py: 1, minHeight: 'auto', fontWeight: 600, color: palette.secondary[40], borderBottom: '1px solid #D4D7DC', flex: 1 }}
        />
        <Tab
          value="transactions"
          label={t('lease.transaction.title2')}
          sx={{ py: 1, minHeight: 'auto', fontWeight: 600, color: palette.secondary[40], borderBottom: '1px solid #D4D7DC', flex: 1 }}
        />
      </Tabs>
      {selectedTab === 'resman' ? (
        <Stack gap={3} sx={{ overflow: 'auto', p: 3, pt: 1, mb: 2 }}>
          {person.balance && person.balance.length ? (
            <ResmanLedgerItem
              key={person.id + '_balance'}
              transaction={{ TransactionCategory: 'Current Balance', date: new Date(), amount: '' + person.balance[0].Balance, type: 'Total' }}
            />
          ) : (
            <></>
          )}
          {transactions.map(transaction => (
            <ResmanLedgerItem key={transaction.transactionId} transaction={transaction} />
          ))}
        </Stack>
      ) : (
        <Stack gap={3} sx={{ overflow: 'auto', p: 3, pt: 1, mb: 2 }}>
          {person.balance && person.balance.length ? (
            <ResmanLedgerItem
              key={person.id + '_balance'}
              transaction={{ TransactionCategory: 'Current Balance', date: new Date(), amount: '' + person.balance[0].Balance, type: 'Total' }}
            />
          ) : (
            <></>
          )}
          {person.relatedUser?.transactions ? (
            <>
              {person.relatedUser.transactions
                .filter(t => t.leaseId === lease?.id)
                .sort((t1, t2) => new Date(t2.createdDate) - new Date(t1.createdDate))
                .map(transaction => (
                  <TransactionItem key={transaction.id} transaction={transaction} person={person} />
                ))}
            </>
          ) : (
            <Typography>{t('lease.transaction.empty')}</Typography>
          )}
        </Stack>
      )}
      <Button fullWidth variant="text" onClick={onClose} sx={{ fontSize: '14px', fontWeight: 500, textDecoration: 'underline' }}>
        {t('common.close')}
      </Button>
    </Dialog>
  );
}

function TransactionItem({ transaction, person }) {
  const paymentMethod = person?.relatedUser?.paymentMethods?.find(pM => pM.gatewayPayerId === transaction.gatewayPayerId);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box>
        <Typography color="grey.800" sx={{ fontWeight: 600 }}>
          {paymentMethod?.type === 'CARD'
            ? `Payment Initiated - xxxx${paymentMethod.lastFourDigits}`
            : paymentMethod?.type === 'BANK'
              ? `Payment Initiated - ACH`
              : transaction.paymentMode}
        </Typography>
        <Typography variant="caption" color="grey.500">
          {transaction.createdDate
            ? `${moment(transaction.createdDate).format('MM/DD/YY')}(${startCase(lowerCase(transaction.paymentMode))}) - Txn: ${transaction.transactionId ?? 'N.A'}`
            : ''}
        </Typography>
        <br></br>
        <Typography variant="caption" color="grey.500" fontWeight={'bold'}>
          {transaction.status === 'FAILED' ? `Failed Reason: ` + transaction.failureReason : ``}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography color="grey.800" sx={{ fontWeight: 600 }}>
          {formatter.format(transaction.totalAmount)}
        </Typography>
        <Chip variant="filled" size="small" label={transaction.status} sx={{ ...(colors[transaction.status] ?? {}) }} />
      </Box>
    </Stack>
  );
}

function ResmanLedgerItem({ transaction }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box>
        <Typography color="grey.800" sx={{ fontWeight: 600 }}>
          {transaction.TransactionCategory ?? transaction.category?.name}
        </Typography>
        <Typography variant="caption" color="grey.500">
          {transaction.date ? moment(transaction.date).format('MM/DD/YY') : ''}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography color="grey.800" sx={{ fontWeight: 600 }}>
          {formatter.format(transaction.amount ?? 0)}
        </Typography>
        <Chip variant="filled" size="small" label={transaction.type} sx={{ ...(colors[transaction.type] ?? {}) }} />
      </Box>
    </Stack>
  );
}

const colors = {
  Payment: {
    backgroundColor: palette.success[70],
    color: palette.success[50],
  },
  Rent: {
    backgroundColor: palette.primary[10],
    color: palette.primary[40],
  },
  APPROVED: {
    backgroundColor: palette.success[70],
    color: palette.success[50],
  },
  FAILED: {
    backgroundColor: palette.error[75],
    color: palette.error[50],
  },
  Total: {
    backgroundColor: palette.primary[10],
    color: palette.primary[40],
  },
};
