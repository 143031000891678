import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function LogoutConfirm({ handleClose }) {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  return (
    <Dialog
      size="lg"
      open
      onClose={(ev, reason) => {
        if (reason && reason === 'backdropClick') {
          return;
        }
        handleClose();
      }}
    >
      <DialogContent
        sx={{
          '& .MuiDialogContent-root': {
            padding: 0,
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 1.5,
          }}
        >
          {t('logout.title')}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 400 }}>
          {t('logout.description')}
        </Typography>
        <Grid container sx={{ mt: 5 }} columnSpacing={3}>
          <Grid item xs={6}>
            <Button onClick={handleClose} fullWidth variant="outlined" color="primary" sx={{ flex: 1 }}>
              {t('logout.return')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={handleLogout}
              variant="contained"
              sx={{
                flex: 1,
              }}
            >
              {t('logout.title')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
