import { createTheme } from '@mui/material/styles';
import palette from './palette';

const theme = createTheme({
  palette: {
    common: {
      white: palette.white,
    },
    primary: {
      main: palette.primary[50],
      dark: palette.primary[90],
      light: palette.primary[30],
      100: palette.primary[10],
      200: palette.primary[20],
      400: palette.primary[40],
      800: palette.primary[80],
    },
    secondary: {
      main: palette.secondary[10],
      100: palette.secondary[10],
    },
    success: {
      main: palette.success[60],
      600: palette.success[60],
    },
    error: {
      main: palette.error[60],
      600: palette.error[60],
    },
    grey: {
      300: palette.grey[30],
      400: palette.grey[40],
      500: palette.grey[50],
      600: palette.grey[60],
      700: palette.grey[70],
      800: palette.grey[80],
      900: palette.grey[90],
    },
    text: {
      primary: palette.text.primary,
      secondary: palette.text.secondary,
    },
  },
});

const customTheme = createTheme(theme, {
  typography: {
    allVariants: {
      color: palette.text.primary,
    },
    h1: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 36,
      lineHeight: 1.08,
      fontWeight: 400,
    },
    h2: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 32,
      fontWeight: 500,
      lineHeight: 36 / 32,
    },
    h3: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 32 / 24,
    },
    h4: {
      fontFamily: ['Hellix', 'serif'].join(','),
      letterSpacing: 0,
      fontSize: 20,
      lineHeight: 32 / 24,
    },
    h5: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 24 / 16,
    },
    subtitle1: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 14,
      lineHeight: 20 / 14,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.43,
    },
    body2: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: 0,
    },
    overline: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 10,
      lineHeight: 1.6,
      letterSpacing: 0.4,
      textTransform: 'initial',
    },
    caption: {
      fontFamily: ['Hellix', 'serif'].join(','),
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.02em',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.MuiButton-sizeMedium': {
            borderRadius: '8px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.625rem 1rem',
          fontSize: '1rem',
          lineHeight: 1.5,
          fontWeight: 600,
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '&.MuiChip-sizeMedium': {
            height: '20px',
            fontSize: '14px',
          },
          '&.MuiChip-sizeSmall': {
            height: '18px',
            fontSize: '10px',
          },
          '&.MuiChip-colorSuccess': {
            backgroundColor: palette.success['70'],
            color: palette.success['50'],
          },
          '&.MuiChip-colorInfo': {
            backgroundColor: palette.primary[10],
            color: palette.primary[40],
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D4D7DC',
            borderRadius: 8,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D4D7DC',
            borderRadius: 8,
          },
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiInputBase-input': {
    //         padding: "12px 16px"
    //       }
    //     }
    //   }
    // }
  },
});

export default customTheme;
