import { Box, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../shared/service/dataprovider';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import palette from '../../shared/theme/palette';
import { useEffect, useMemo, useState } from 'react';
import LeaseResident from './LeaseResident';
import { useTranslation } from 'react-i18next';

export default function LeaseDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, error } = useQuery({
    queryKey: [`leaseDetail/${id}`],
    queryFn: () => api.get(`/v1/admin/lease/${id}`),
  });
  const lease = data?.data?.lease;

  const handleBackToSeach = () => {
    navigate('/dashboard');
  };

  return (
    <Box>
      <Stack flexDirection="row" alignItems="center" sx={{ cursor: 'pointer', mb: 3 }} onClick={handleBackToSeach}>
        <ChevronLeftIcon />
        <Typography>{t('lease.back')}</Typography>
      </Stack>
      {lease && (
        <>
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography variant="h2">{lease['Unit']}</Typography>
              <Divider orientation="vertical" sx={{ width: '3px', height: '24px' }} />
              <Typography variant="h2" sx={{ color: palette.grey['30'] }}>
                {lease.property?.name}
              </Typography>
            </Stack>
          </Stack>
          <LeaseDetailContent lease={lease} />
        </>
      )}
      {error && (
        <Typography variant="h4" sx={{ color: palette.error[50] }}>
          {error?.response?.data?.message ?? `There is an error for loading lease ${id}. Try again later`}
        </Typography>
      )}
    </Box>
  );
}

function LeaseDetailContent({ lease }) {
  const [selectedLeaseLink, setSelectedLeaseLink] = useState(null);

  const leaseLinks = useMemo(() => {
    return lease.leaseLinks ?? [];
  }, [lease]);
  const isMultipleLeases = leaseLinks.length > 1;

  useEffect(() => {
    if (!selectedLeaseLink && leaseLinks?.length > 0) {
      setSelectedLeaseLink(leaseLinks[0]);
    } else if (selectedLeaseLink) {
      setSelectedLeaseLink(leaseLinks.find(a => a.id === selectedLeaseLink.id));
    }
  }, [leaseLinks]);

  return (
    <>
      <Box sx={{ mb: 3 }}>
        {isMultipleLeases && (
          <Tabs
            value={selectedLeaseLink?.id}
            onChange={(_e, value) => {
              const applicant = leaseLinks.find(applicant => applicant.id === value);
              if (applicant) {
                setSelectedLeaseLink(applicant);
              }
            }}
          >
            {leaseLinks.map(leaseLink => (
              <Tab key={leaseLink.id} value={leaseLink.id} label={leaseLink.people.FirstName} sx={{ borderBottom: '1px solid #D4D7DC' }} />
            ))}
          </Tabs>
        )}
      </Box>
      {selectedLeaseLink && <LeaseResident lease={lease} leaseLink={selectedLeaseLink} />}
    </>
  );
}
