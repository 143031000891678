import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../shared/service/dataprovider';
import { Box, Divider, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export default function Reports() {
  const queryClient = useQueryClient();
  const [data, setData] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState('');
  const fetchData = async propertyId => {
    setSelectedProperty(propertyId);
    const result = await queryClient.fetchQuery({
      queryKey: ['lazyData', propertyId],
      queryFn: () => api.get(propertyId ? `/v1/admin/metabase-link?propertyId=${propertyId}` : `/v1/admin/metabase-link`),
    });
    setData(result);
  };
  const { data: properties } = useQuery({
    queryKey: ['properties'],
    queryFn: () => api.get('/v1/admin/properties'),
  });

  useEffect(() => {
    if (properties?.data?.length > 0) {
      fetchData(properties?.data[0].id).catch();
    }
  }, [properties?.data]);
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography variant="h2">Select Property</Typography>
            <Divider orientation="vertical" sx={{ width: '3px', height: '24px' }} />
            <Select
              variant="standard"
              onChange={e => {
                fetchData(e.target.value).catch();
              }}
              value={selectedProperty}
              sx={{ fontSize: '21px', '::before': { borderBottom: '0px' } }}
            >
              {properties?.data.map(p => (
                <MenuItem key={p.id} value={p.id}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
      </Box>
      <iframe src={data?.data?.url} width="100%" height="1000px" />
    </>
  );
}
