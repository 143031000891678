export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const shouldForwardProp = propList => {
  return prop => !propList.includes(prop);
};

export const getPersonFullName = person => {
  let name = person['FirstName'];
  if (person['LastName']) {
    name += ` ${person['LastName']}`;
  }

  return name;
};

export function getOrdinalSuffix(num) {
  if (!num) return '';
  const numStr = num.toString();

  if (numStr.endsWith('11') || numStr.endsWith('12') || numStr.endsWith('13')) {
    return numStr + 'th';
  }

  const lastDigit = num % 10;

  // Determine the suffix based on the last digit
  switch (lastDigit) {
    case 1:
      return numStr + 'st';
    case 2:
      return numStr + 'nd';
    case 3:
      return numStr + 'rd';
    default:
      return numStr + 'th';
  }
}

export const formatPhoneNumber = phoneNumber => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumber;
};
