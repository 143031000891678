import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import palette from '../../shared/theme/palette';
import { ReactComponent as BankIcon } from '../../assets/svg/bank.svg';
import { ReactComponent as CardIcon } from '../../assets/svg/card.svg';
import { getOrdinalSuffix } from '../../shared/util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

export default function PaymentInfo({ person }) {
  const { t } = useTranslation();
  const relatedUser = person.relatedUser;

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 2,
        boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', color: palette.grey[50], mb: 3 }}>{t('lease.payment.title')}</Typography>
      {!relatedUser || !relatedUser.paymentMethods || !relatedUser.paymentMethods?.length ? (
        <Box>
          <Typography>{t('lease.payment.empty')}</Typography>
        </Box>
      ) : (
        <Box>
          <Stack gap={3}>
            {relatedUser.paymentMethods
              ?.filter(pM => pM.status !== 'DELETED')
              .map(paymentMethod => (
                <Stack key={paymentMethod.id} direction="row" alignItems="center" justifyContent="space-between">
                  <Stack direction="row" alignItems="center" gap={1}>
                    {paymentMethod.type === 'CARD' ? <CardIcon /> : <BankIcon />}
                    <Box>
                      <Typography color="grey.900" sx={{ fontWeight: 600 }}>
                        {paymentMethod.cardType}
                      </Typography>
                      <Typography variant="caption" color="grey.600">
                        {`${paymentMethod.type.toLowerCase()[0].toUpperCase()}${paymentMethod.type.toLowerCase().slice(1)}`}
                        <Box
                          component="span"
                          sx={{ pl: 1 }}
                        >{`• • • • • ${paymentMethod.type === 'BANK' ? paymentMethod.bankAccountNo : paymentMethod.lastFourDigits}`}</Box>
                      </Typography>
                    </Box>
                  </Stack>
                  {paymentMethod.default && <Chip label={t('common.default')} color="info" />}
                </Stack>
              ))}
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography color="grey.500">{t('lease.payment.autoPay')}</Typography>
            <Chip
              label={relatedUser.autoPay?.isActive ? t('lease.payment.on') : t('lease.payment.off')}
              color={relatedUser.autoPay?.isActive ? 'success' : 'info'}
            />
          </Stack>
          {relatedUser.autoPay?.isActive && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography color="grey.500">{t('lease.payment.autoPayDate')}</Typography>
              <Typography color="grey.800">{`${getOrdinalSuffix(relatedUser.autoPay?.dayOfMonth)} of the Month`}</Typography>
            </Stack>
          )}
          <Divider sx={{ my: 2 }} />
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography color="grey.500">{t('lease.payment.status')}</Typography>
            <Chip
              label={
                person.balance && person.balance[0].PaymentStatus === 'Accept'
                  ? t('lease.payment.enabled')
                  : person.balance[0].PaymentStatus === 'Certified Funds Only'
                    ? t('lease.payment.certifiedFunds')
                    : t('lease.payment.disabled')
              }
              color={
                person.balance && person.balance[0].PaymentStatus === 'Accept'
                  ? 'success'
                  : person.balance[0].PaymentStatus === 'Certified Funds Only'
                    ? 'default'
                    : 'info'
              }
              sx={person.balance && person.balance[0].PaymentStatus === 'Certified Funds Only' ? { background: '#FCF4DA', color: '#E49900' } : {}}
            />
          </Stack>
          {!(person.balance && (person.balance[0].PaymentStatus === 'Accept' || person.balance[0].PaymentStatus === 'Certified Funds Only')) && (
            <Stack direction="row" alignItems="center" gap={1} sx={{ pt: 1.5 }}>
              <InfoOutlinedIcon sx={{ color: palette.grey[40] }} />
              <Typography variant="caption" color="grey.600">
                {t('lease.payment.description')}
              </Typography>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
}
