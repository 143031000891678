import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import palette from '../shared/theme/palette';

export const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 370,
    backgroundColor: 'white',
    border: '1px solid #D4D7DC',
    borderRadius: 5,
    color: palette.text.primary,
    fontSize: '14px',
    padding: '6px 8px',
    textAlign: 'center',
  },
});
