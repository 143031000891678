import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../components/loader';
import { Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { prepareHeaders } from '../shared/service/apiConfig';
import { useQuery } from '@tanstack/react-query';
import { api } from '../shared/service/dataprovider';
import { FullStory } from '@fullstory/browser';

export default function PrivateRouteProvider() {
  const { isAuthenticated, isLoading } = useAuth0();
  const [loadingToken, setLoadingToken] = useState(true);

  const handleGetUser = async () => {
    if (isAuthenticated) {
      const prepared = await prepareHeaders();
      setLoadingToken(!prepared);
    }
  };

  useEffect(() => {
    handleGetUser();
  }, [isAuthenticated]);

  return (
    <>
      {(isLoading || loadingToken) && <Loader />}
      {isAuthenticated && !isLoading && !loadingToken && <PrivateOutlet />}
      {!isAuthenticated && !isLoading && <Navigate to="/" />}
    </>
  );
}

function PrivateOutlet() {
  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: () => api.get('/v1/admin/profile'),
  });

  useEffect(() => {
    if (data && data.data) {
      FullStory('setIdentity', {
        uid: data.data.id,
        properties: {
          email: data.data.email,
          isSuperAdmin: data.data.isSuperAdmin,
        },
      });
    }
  }, [data]);

  return <Outlet />;
}
