import { configs } from '../../config';
import { api } from './dataprovider';

let getAccessTokenSilently;

export const securityOptions = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: func => {
    getAccessTokenSilently = func;
  },
};

export const prepareHeaders = async () => {
  const access_token = await securityOptions.getAccessTokenSilently({ authorizationParams: { audience: configs.api_audience } })();

  api.defaults.headers.common.Authorization = `Bearer ${access_token}`;

  return true;
};
