const palette = {
  white: 'white',
  primary: {
    10: '#CDDEE1',
    20: '#AFCBCF',
    30: '#74A3AC',
    40: '#5C7072',
    50: '#387C88',
    80: '#2C366B',
    90: '#131D53',
    100: '#E9F0FC',
  },
  secondary: {
    10: '#18A0FB',
    40: '#395154',
    50: '#FA952B',
  },
  grey: {
    20: '#D5D7DB',
    40: '#9FA1A6',
    30: '#BABDC2',
    50: '#7E8085',
    60: '#606266',
    70: '#646464',
    80: '#2C2D30',
    90: '#141518',
  },
  success: {
    10: '#E2F9EE',
    20: '#C6F7E2',
    50: '#357219',
    60: '#27AB83',
    70: '#DDF5C7',
    80: '#147D64',
  },
  error: {
    20: '#FACDCD',
    50: '#A4233F',
    60: '#BA2525',
    75: '#FBE8F1',
    80: '#911111',
    90: '#A61B1B',
  },
  warning: {
    20: '#FFF3C4',
    70: '#FCEDB9',
    80: '#CB6E17',
  },
  text: {
    primary: '#212121',
    secondary: '#52505E',
  },
  dark: {
    tertiary: '#27293759',
    notes: {
      70: '52505E',
    },
  },
  background: {
    dark: {
      20: '#F5F5F5',
    },
  },
};

export default palette;
