import { Route, Routes } from 'react-router-dom';
import LandingPage from './landing';
import PrivateRouteProvider from '../providers/PrivateRouteProvider';
import Properties from './properties';
import LeaseDetail from './LeaseDetail';
import Layout from '../components/Layout';
import Reports from './Reports';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/" element={<PrivateRouteProvider />}>
        <Route
          path="/dashboard"
          element={
            <Layout>
              <Properties />
            </Layout>
          }
        />
        <Route
          path="/lease/:id"
          element={
            <Layout>
              <LeaseDetail />
            </Layout>
          }
        />
        <Route
          path="/reports"
          element={
            <Layout>
              <Reports />
            </Layout>
          }
        />
      </Route>
    </Routes>
  );
};
