import { Box, ButtonBase, Grid, Stack, styled, Typography } from '@mui/material';
import { ReactComponent as ReportsDashImg } from '../../assets/svg/landing.svg';
import { ReactComponent as GoogleLogo } from '../../assets/svg/google_logo.svg';
import { ReactComponent as Logo } from '../../assets/svg/tenantport_logo.svg';
import { useAuth0 } from '@auth0/auth0-react';
import palette from '../../shared/theme/palette';
import { IMAGES } from '../../shared/constant/image';
import { useTranslation } from 'react-i18next';

const SignInButton = styled(ButtonBase)(() => ({
  padding: '1rem 1.25rem',
  borderRadius: '.5rem',
  border: `1px solid ${palette.dark.tertiary}`,
  width: '100%',
  maxWidth: '26rem',
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: '.625rem',
}));

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  const handleSignin = async () => {
    await loginWithRedirect({
      authorizationParams: {
        responseType: 'code',
        redirectUri: `${window.location.origin}`,
        mode: 'login',
      },
      appState: {
        returnTo: '/dashboard',
      },
    });
  };

  return (
    <Box sx={{ height: '100vh' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={6} sx={{ height: '100%', px: 4 }}>
          <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Logo />
            <Typography sx={{ fontSize: '2.5rem', lineHeight: 1.1, mb: '3.25rem', mt: 3 }}>{t('landing.title')}</Typography>
            <SignInButton onClick={handleSignin}>
              <GoogleLogo /> {t('landing.signin')}
            </SignInButton>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            height: '100%',
            borderLeft: `1px solid ${palette.primary[40]}`,
            backgroundImage: `url(${IMAGES.LANDING})`,
            backgroundSize: 'cover',
          }}
        />
      </Grid>
    </Box>
  );
}
