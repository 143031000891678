import { useAuth0 } from '@auth0/auth0-react';
import { AppRoutes } from './pages/routes';
import { securityOptions } from './shared/service/apiConfig';

function App() {
  const { getAccessTokenSilently } = useAuth0();

  securityOptions.setAccessTokenSilently(getAccessTokenSilently);

  return <AppRoutes />;
}

export default App;
